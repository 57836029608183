<template>
  <MainPanel>
    <a-card style="width: 100%; min-height: 100vh;">
      <a-row :gutter="24" style="margin-left: 10%;margin-right: 10%;">
        <a-col :span="24">
          <a-form-item label="">
            <a-input v-model:value="blogWriteParams.theme" placeholder="主题" :allowClear="true" style="width: 100%;" />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <md-editor
                  v-model="content"
                  :toolbars="toolbars"
                  style="width: 100%; min-height: 100vh;"
                  theme="light"
                  :showCodeRowNumber="true"
                  :readOnly="false"
                  @onUploadImg="onUploadImg"
                  @onSave="writeBlog"
                  editorId="blog-write"
                  placeholder=""
          />
        </a-col>
      </a-row>
    </a-card>
  </MainPanel>
</template>

<script>
import MainPanel from "@/components/basic/MainPanel";
import MdEditor from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';

import * as BlogApi from '@/api/BlogApi.js';
import {message} from "ant-design-vue";

export default {
  name: 'WriteBlog',
  components: {
    MainPanel,
    MdEditor
  },
  data() {
    return {
      content: '',

      toolbars: [
        'save',
        '-',
        'image',
        'link',
        '-',
        'quote',
        'title',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        '-',
        'codeRow',
        'code',
        '-',
        'table',
        'orderedList',
        'unorderedList',
        '-',
        'katex',
        'sup',
        'sub',
        '-',
        'mermaid',
        '-',
        'revoke',
        'next',
        '=',
        'preview',
        'htmlPreview',
        '-',
        'pageFullscreen',
        'catalog',
      ],

      blogWriteParams: {
        theme: '',
        content: '',
      }

    }
  },

  methods: {
    onUploadImg(obj) {
      console.log(obj)
    },

    writeBlog(obj) {
      this.blogWriteParams.content = obj;
      BlogApi['blogCreateApi'](this.blogWriteParams)
      .then((result) => {
        let code = result.data.code;
        let msg = result.data.msg;
        if (code === 1) {
          message.success(msg);
        } else {
          message.error(msg);
        }

      })
      .catch((e) => {
        message.error(e.message);
      })
    },

  },

  created() {

  },

  mounted() {

  }

}

</script>


<style>
/*隐藏同步滚动*/
#blog-write .md-editor-footer-right {
  display: none;
}

/*设置文档宽*/
#blog-write .md-editor-content {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

/*设置目录宽*/
#blog-write .md-editor-catalog-editor {
  width: 50%;
}
</style>
